import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';
import showResults from '../../showResults';

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';

import { renderCheckField } from '../fields/fields'



const optionsForm = (props) => {
    const { handleSubmit, pristine, reset, submitting, dekoratori } = props;
    console.log(dekoratori);
    return (

        <form onSubmit={handleSubmit(showResults)}>
            <Row className="selectOptions">
                {dekoratori.map((item, idx) => {
                 
                    return (
                        <Col xl="6" lg="6" md="6" xs="6" key={idx}>
                            <div className="optionFix">
                                <Field component={renderCheckField} type="checkbox" name={item.title} placeholder={item.title}></Field>
                                <p><span>+{item.price} KM</span></p>
                            </div>
                        </Col>
                    )

                })}
            </Row>
            <button>Baton</button>

        </form>


    )

}

export default reduxForm({
    form: 'optionsForm',
})(optionsForm)
