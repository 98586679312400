import React, { Component } from 'react';

import Carousel from '../containers/kids/carousel.js';
import AnimatedButton from '../components/animatedButton';
import Welcome from '../containers/kids/welcome';
import HomeOffer from '../containers/kids/homeOffer.js';
import BirthdayHome from '../containers/kids/birthdayHome.js';

import ActivityOffer from '../containers/kids/activityOffer.js';
import Gallery from '../containers/kidsGallery.js';
import ContactForm from '../containers/contactForm';
import DefaultLayout from '../containers/defaultLayout.js';
import { Link } from 'react-router-dom';
import HappeningComponent from '../containers/kids/happeningComponent.js';
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import image1 from '../images/happening/image1.jpg';
import image2 from '../images/happening/image2.jpg';
import blueBalloon from '../images/animation/blueBalloon.png';
import stars from '../images/animation/stars.png';

import greenBalloon from '../images/animation/greenBalloon.png';
import yellowBalloon2 from '../images/animation/yellowBalloon2.png';
import axios from 'axios';
import moment from 'moment';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }
    componentWillMount() {
        window.scroll(0, 0);
    }


    componentDidMount() {

        const API_KEY = "IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo";

        axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + API_KEY
        }

        axios.get(`https://euroexpress.tips:4002/api/v1/pub/article_all`)
            .then(res => {
                let data = res.data;
                data = data.slice(-2)

                this.setState({ data });
            });

    }

    render() {
        const buttonText = "Pogledaj kompletnu galeriju";
        const buttonText2 = "Pogledaj sva dešavanja";
        return (
            <div>

                <Carousel />
                <Welcome />
                <HomeOffer />
                <ActivityOffer />
                <BirthdayHome />
                <div className="homeGallery">
                    <Container>
                        <Row>
                            <div className="title">
                                <h1>Galerija</h1>
                                <p>{/*----*/}</p>
                            </div>
                        </Row>
                        <Gallery />
                        <div className="homeButtonWrapper">
                            <Link to="/galerija"><AnimatedButton buttonText={buttonText} /></Link>
                        </div>
                    </Container>
                </div>

                <div className="happening">

                    <div className="animatedBalloon">
                        <img src={blueBalloon} className="balloon" />
                        <img src={stars} className="star" />
                    </div>


                    <div className="animatedBalloonRight">
                        <img src={yellowBalloon2} className="balloonRight" />
                        <img src={greenBalloon} className="starRight" />
                    </div>
                    <Container>
                        <h1>Dešavanja</h1>
                        <p>{/*----*/}</p>

                        <Row>
                            {this.state.data.map((items, idx) => {
                                const ENDPOINT = "https://euroexpress.tips:4002";
                                const IMAGES = ENDPOINT + "/images/image_get/";
                                return (
                                    <Col xl="6" lg="6" md="6" xs="12" key={items.id}>
                                        <div className="boxHappening">
                                            <div className="contentHappening">
                                                <Link to={`/desavanja/${this.props.type}/${items.id}`}>
                                                    <h5>{items.title}</h5></Link>
                                                <h6>{moment.unix(items.timestamp).format('DD.MM.YYYY HH:mm')}</h6>
                                                <p>{items.text}</p>
                                            </div>
                                            <div className="image">
                                                <Link to={`/desavanja/${this.props.type}/${items.id}`}>
                                                    <img src={IMAGES + items.banner.url} className="img-fluid" />
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>

                                )
                            })}
                        </Row>

                        <Link to={`/desavanja/${this.props.type}`}><AnimatedButton buttonText={buttonText2} /></Link>
                    </Container>
                </div>

                <ContactForm />
            </div>
        )

    }

}

export default DefaultLayout(Home) 