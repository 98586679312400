import React, { Component } from 'react'
import DefaultLayout from '../../containers/defaultLayout.js';
import ContactForm from '../../containers/contactForm';
import { Link } from 'react-router-dom';
import locationSVG from '../../images/svg/locationSVG.svg';
import phoneSVG from '../../images/svg/phoneSVG.svg';
import clockSVG from '../../images/svg/clockSVG.svg';
import emailSVG from '../../images/svg/emailSVG.svg';
import Isvg from 'react-inlinesvg';

import {
    Container, Row, Col
} from 'reactstrap';
import doubleStars from '../../images/animation/doubleStars.png'

import balloon from '../../images/animation/yellowBalloon.png';
import star from '../../images/animation/star.png';


class contact extends Component {
    componentWillMount() {
        window.scroll(0, 0);
    }
    render() {
        return (
            <div className="kontakt">
                <div className="animatedBalloon">
                    <img src={balloon} className="balloon" />
                    <img src={star} className="star" />
                </div>

                <div className="animatedBalloonRight">
   
    <img src={doubleStars} className="starRight" />
</div>
                <Container>
                    <div className="breadcrumbs">
                        <p><Link to="/">Početna </Link> Kontakt</p>
                    </div>
                    <div className="content">
                        <h1>Kontakt</h1>
                        <p>Za sve informacije kontaktirajte nas svakim danom od 10h do 22h putem Kontakt forme, E-mail-a ili telefona.</p>
                    </div>
                    <Row className="contactRow">
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="box">
                                <Row>
                                    <Col xl="1" lg="2" md="2" xs="2">
                                        <Isvg src={locationSVG} />
                                    </Col>
                                    <Col xl="11" lg="10" md="10" xs="10">
                                        <div className="text">
                                            <span>Adresa</span>
                                            <p>Jovana Dučića 25, 78000 Banja Luka</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="box">
                                <Row>
                                    <Col xl="1" lg="2" md="2" xs="2">
                                        <Isvg src={phoneSVG} />
                                    </Col>
                                    <Col xl="11" lg="10" md="10" xs="10">
                                        <div className="text">
                                            <span>Telefon:</span>
                                            <p>+387 65 006 800</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="box">
                                <Row>
                                    <Col xl="1" lg="2" md="2" xs="2">
                                        <Isvg src={emailSVG} />
                                    </Col>
                                    <Col xl="11" lg="10" md="10" xs="10">
                                        <div className="text">
                                            <span>E-mail:</span>
                                            <p>info@wunderland.ba</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <div className="box">
                                <Row>
                                    <Col xl="1" lg="2" md="2" xs="2">
                                        <Isvg src={clockSVG} />
                                    </Col>
                                    <Col xl="11" lg="10" md="10" xs="10">
                                        <div className="text">
                                            <span>Radno vrijeme PON-PET:</span>
                                            <p>15:00 - 21:00h</p>
                                            <span>Radno vrijeme SUB-NED:</span>
                                            <p>10:00 - 21:00h</p>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ContactForm />
            </div>
        )
    }
}

export default DefaultLayout(contact)
