import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { registerLocale, setDefaultLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';

class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="position-relative form-group">
                <input className={this.props.error ? 'form-control required' : 'form-control'} type={this.props.type} onChange={this.props.onChange} value={this.props.value} placeholder={this.props.placeholder} />
            </div>
        );
    }
}


class Textarea extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <textarea className={this.props.error ? 'form-control required' : 'form-control'} onChange={this.props.onChange} value={this.props.value} placeholder={this.props.placeholder}></textarea>
            </div>
        );
    }
}

class CheckComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }


    }

    render() {
        return (
            <div className="optionWrapper">
                <div onClick={() => this.props.onChange(!this.props.value)}>
                    <label className="switch">
                        <input type={this.props.type} value={this.props.value} />
                        <span className="slajder round"></span>
                    </label>
                </div>
                <p>{this.props.placeholder}<span>{this.props.label}</span></p>
            </div>
        )
    }

}

class DateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        if (!this.props.value) {
            this.props.onChange();
        }
    }

    render() {
        return (
            <DatePicker
                placeholderText={this.props.placeholder}
                selected={this.props.value}
                onChange={this.props.onChange}
                dateFormat="yyyy/MM/dd"
                className={this.props.error ? 'required' : null}

            />
        )
    }
}

class TimeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: '10:00'
        }
    }
    componentDidMount() {
        if (!this.props.value) {
            this.props.onChange(this.setState({ time: this.state.time }));
        }
    }

    render() {
        return (

            <TimePicker
                onChange={this.props.onChange}
                value={this.props.value}
                className={this.props.error ? 'required' : null}
                disableClock
                placeholderText={this.props.placeholder}
                format="HH:mm"
            />
        )
    }
}
export const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error, warning },
    requiredField
}) => (
        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}
            requiredField={requiredField}
            {...input}
        />
    )

export const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (
        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

export const renderCheckField = ({
    input,
    placeholder,
    type,
    label,
    children,
    meta: { touched, error },
}) => (

        <CheckComponent
            placeholder={placeholder}
            type={type}
            errorText={touched && error}
            error={touched && error}
            children={children}
            {...input}
        />
    )

export const renderDateField = ({
    input,
    meta: { touched, error },
    placeholder
}) => (

        <DateComponent
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )


export const renderTimeField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <TimeComponent
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Unesite E-mail adresu u pravilnom formatu' : undefined;


