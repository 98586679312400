import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import image1 from '../../images/homeOffers/1.png';
import image2 from '../../images/homeOffers/2.png';
import image3 from '../../images/homeOffers/3.png';
import {
    Container, Row, Col
} from 'reactstrap';

export class homeOffer extends Component {
    render() {
        return (
            <div className="homeOffer">


                <Container>
                    <h1>Šta vas čeka u Wunderlandu</h1>
                    <Row>
                        <Col xl="4" lg="4" md="4" xs="12">
                            <div className="wrapper">
                                <div className="wrappBox">
                                    <div className="box">
                                        <img src={image1} className="img-fluid" />
                                    </div>
                                </div>
                                <h3>Prostor preko<br /> 2000m²</h3>
                                <p>Smještena u centru grada
                                na preko 2000m²,
                                igraonica Wunderland
                                nudi raznovsne sadržaje</p>
                            </div>
                        </Col>

                        <Col xl="4" lg="4" md="4" xs="12">
                            <div className="wrapper">
                                <div className="wrappBox">
                                    <div className="box">
                                        <img src={image2} className="img-fluid" />
                                    </div>
                                </div>
                                <h3>Veliki izbor<br /> hrane i pića</h3>
                                <p>Nudimo vam
                                veliki izbor hrane i pića
                                kako za djecu, tako i za
                                odrasle</p>
                            </div>
                        </Col>

                        <Col xl="4" lg="4" md="4" xs="12">
                            <div className="wrapper">

                                <div className="wrappBox">
                                    <div className="box">
                                        <img src={image3} className="img-fluid" />
                                    </div>

                                </div>
                                <h3>Prostor za roditelje<br /> i druge posjetioce</h3>
                                <p>Za roditelje i druge posjetioce
                               smo uredili kutak sa ugodnim
                               ambijentom za odmor i
                        uživanje</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default homeOffer
