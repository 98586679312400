import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../../containers/defaultLayout.js';
import AnimatedButton from '../../components/animatedButton';
import HappeningComponent from '../../containers/kids/happeningComponent.js';

import ContactForm from '../../containers/contactForm.js';

import {
    Container, Row, Col
} from 'reactstrap';

import rectangle from '../../images/rectangle.png';
import image1 from '../../images/happening/image1.jpg';
import image2 from '../../images/happening/image2.jpg';

import balloon from '../../images/animation/yellowBalloon.png';
import star from '../../images/animation/star.png';

import doubleStars from '../../images/animation/doubleStars.png';
import redBallonRight from '../../images/animation/redBallonRight.png';

import blueBalloon from '../../images/animation/blueBalloon.png';
import stars from '../../images/animation/stars.png';

import yellowBalloon2 from '../../images/animation/yellowBalloon2.png';
import greenBalloon from '../../images/animation/greenBalloon.png';

import laser from '../../images/laser.png';


import laser2 from '../../images/laser/laser.png';
import happeningLaser from '../../images/laser/happeningLaser.png';
import axios from 'axios';
import moment from 'moment';



export class about extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        }
    }

    componentWillMount() {
        window.scroll(0, 0);
    }


    componentDidMount() {
        const API_KEY = "IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo";

        axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + API_KEY
        }

        axios.get(`https://euroexpress.tips:4003/api/v1/pub/article_all`)
            .then(res => {
                let data = res.data;
                data = data.slice(-2)

                this.setState({ data });
            });


    }
    render() {
        const buttonText = "Pogledaj sva dešavanja";
        return (
            <div className="about">
                <div className="vrAnim">
                    <img src={happeningLaser} className="leftVr" />
                    <img src={laser2} className="rightVr" />
                </div>
                <Container>
                    <div className="breadcrumbs">
                        <p><Link to="/">Početna </Link>  O nama</p>
                    </div>


                    <div className="content">
                        <h1>O nama</h1>

                        <p>Igraonica Wunderland predstavlja do sada neviđeni koncept igre i zabave u Banjaluci. Smještena u centru grada
                        na preko 2000m², ova igraonica nudi raznovrsne sadržaje prilagođene kako djeci i mladima tako i odraslima koji
                        su spremni da se upuste u svijet Virtual reality avantura. Prostor obuhvata odvojenu igraonicu za najmlađe (bebe i
                        djecu do 3 godine) čiji su sadržaji prilagođeni njihovim razvojnim i motoričkim sposobnostima, kao i igraonicu za
                        djecu starijeg uzrasta koju čine trampoline, tobogani, lavirinti, penjalice, autići i slični sadržaji.</p>

                        <div className="imgWrapp">
                            <img src={laser} className="img-fluid" />
                        </div>

                        <p>Ono što Wunderland zaista čini "zemljom čuda" predstavlja program Virtual reality igara za sve uzraste i
                        prostrana Laser tag arena sa najsavremenijom opremom, koji zabavu podižu na sasvim novi nivo potpunog
                        doživljaja virtuelne stvarnosti. Za roditelje i druge posjetioce smo uredili kutak sa ugodnim ambijentom za odmor i
                        uživanje. Posjetite nas i uživajte u svijetu svakodnevne igre i zabave, ili nam prepustite organizaciju proslava
                        dječijih rođendana. Dođite i uvjerite se sami u jedinstven koncept zabave u našem gradu, te saznajte zašto su svi
                        drugari već u Wunderlandu!</p>
                    </div>
                </Container>

                <div className="happening">



                    <Container>
                        <h1>Dešavanja</h1>
                        <p>{/*----*/}</p>

                        <Row>
                            {this.state.data.map((items, idx) => {
                                const ENDPOINT = "https://euroexpress.tips:4002";
                                const IMAGES = ENDPOINT + "/images/image_get/";
                                return (
                                    <Col xl="6" lg="6" md="6" xs="12" key={items.id}>
                                        <div className="boxHappening">
                                            <div className="contentHappening">
                                                <Link to={`/desavanja/${this.props.type}/${items.id}`}>
                                                    <h5>{items.title}</h5></Link>
                                                <h6>{moment.unix(items.timestamp).format('DD.MM.YYYY HH:mm')}</h6>
                                                <p>{items.text}</p>
                                            </div>
                                            <div className="image">
                                                <Link to={`/desavanja/${this.props.type}/${items.id}`}>
                                                    <img src={IMAGES + items.banner.url} className="img-fluid" />
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>

                                )
                            })}
                        </Row>

                        <Link to={`/desavanja/${this.props.type}`}><AnimatedButton buttonText={buttonText} /></Link>
                    </Container>
                </div>
                <ContactForm />

            </div>
        )
    }
}

export default DefaultLayout(about)
