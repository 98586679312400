import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import OptionForm from '../../components/forms/optionForm';
import DefaultLayout from '../../containers/defaultLayout.js';
import AnimatedButton from '../../components/animatedButton.js';
import ReservationForm from '../../components/forms/reservationForm';
import ReserveFrom from '../../components/forms/reserveForm';

import axios from 'axios';

import {
    Container, Row, Col
} from 'reactstrap';
import image from '../../images/image.png';

import { renderCheckField } from '../../components/fields/fields.js'



export class birthdayDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            decorators: [],
            foodDecorators: [],
            totalPrice: 0,
            done: false,
            image: {}

        }
        this.onSubmit = this.onSubmit.bind(this);


    }

    componentDidMount() {

        const API_KEY = "IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo";

        axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + API_KEY
        }

        axios.get(`https://euroexpress.tips:4002/api/v1/pub/offers_get`)
            .then(res => {
                const data = res.data;
                //console.log(data);
                //this.setState({ data });

                for (let i = 0; i < data.length; i++) {
                    console.log(data)
                    if (data[i].id == this.props[0].match.params.id) {
                        console.log(data[i]);
                        this.setState(data[i]);
                        return;
                    }
                }
            })
    }

    onSubmit = (data) => {
        console.log(data);
        const API_KEY = "IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo";
        const url = "https://euroexpress.tips:4002/api/v1/pub/place_simple";
        
        let timestamp = Math.floor(data.reservation_date.getTime() / 1000);
        data.from = timestamp + (data.timeFrom.split(':')[0] * 60 + parseInt(data.timeFrom.split(':')[1])) * 60;
        data.to = timestamp + (data.timeTo.split(':')[0] * 60 + parseInt(data.timeTo.split(':')[1])) * 60;

        delete data.timeFrom;
        delete data.timeTo;
        delete data.reservation_date;
        console.log(data);

        axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + API_KEY
        }
        axios.post(url, data)
            .then(this.setState({ done: true }))
            .catch(err => console.log(err.data))
    
        }


    componentWillMount() {
        window.scroll(0, 0);
    }
    render() {
        console.log(this.state);
        let dekoratori = this.state.dekoratori;
        const buttonText = "Odaberite dodatne opcije";
        const buttonText2 = "Odaberite hranu";
        return (
            <div className="birthdayDetail">

                <Container>
                    <div className="breadcrumbs">
                        <p><Link to="/">Početna </Link>  Rođendani</p>
                    </div>

                    <div className="content">
                        <h1>Rezervacija</h1>
                    </div>
                    <Row className="birthdayRow">
                        <Col xl="5" lg="5" md="5">
                            <img src={`https://euroexpress.tips:4002/images/image_get/${this.state.image.url}`} className="img-fluid" />
                        </Col>
                        <Col xl="7" lg="7" md="7" xs="12">
                            <h6>Paket uključuje sledeće:</h6>
                            <Row>
                                <Col lg="12">
                                    <div dangerouslySetInnerHTML={{__html: this.state.description && this.state.description.replace(/\n/g, '<br/>')}}></div>
                                </Col>
                                {/*
                                <Col xl="4" lg="4" md="4" xs="6">
                                    <p>- Teta</p>
                                    <p>- Zakup*</p>
                                    <p>- Separe*</p>
                                </Col>
                                <Col xl="4" lg="4" md="4" xs="6">
                                    <p>- Grickalice</p>
                                    <p>- Baby corner</p>
                                    <p>- Igraonica 4+ </p>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <div className="spacer">
                                        <p>* Zadržavanje djece poslije rođendana naplaćuje se 3km po svakom započetom satu</p>
                                        <p>* Uz rezervaciju obavezna konzumacija hrane i pića</p>
                                        <p>* Za svaku sledeću osobu na rođendanu doplaćuje se 10km</p>
                                    </div>
                                </Col>
                                */}
                            </Row>
                        </Col>
                    </Row>

                </Container>
                {/*
                <div>
                    <Container>
                        <Row>
                            <Col xl="4" lg="4" md="5" xs="12">

                                <h6 className="newh6">{this.state.title} - {this.state.originalPrice} KM</h6>
                                <h6 className="newh6-2">UKUPNO: &nbsp;&nbsp;<span>{this.state.totalPrice} KM</span></h6>

                            </Col>
                        </Row>
                        <Row noGutters className="optionButtons">
                            <AnimatedButton buttonText={buttonText} />
                            <AnimatedButton buttonText={buttonText2} />
                        </Row>
                        <Row noGutters>
                            {<OptionForm onSubmit={this.OptionForm} dekoratori={this.state.decorators} />}
                        </Row>

                    </Container>
                    <div className="reservationForm">
                        <ReservationForm onSubmit={this.onSubmit} />
                    </div>
                </div>
                */}

                <ReserveFrom {...this.state} onSubmit={this.onSubmit}></ReserveFrom>

                {this.state.done ?
                    <Container>
                        <div class="alert alert-info mt-n5 mb-5" role="alert">Vaša Wunderland rezervacija je zapremljena, očekujte poziv uskoro!</div>
                    </Container> : null}

            </div>
        )
    }
}

export default DefaultLayout(birthdayDetail)
