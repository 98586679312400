import React, { Component } from 'react'
import DefaultLayout from '../containers/defaultLayout.js';
import Carousel from '../containers/vr/carousel.js';
import Welcome from '../containers/vr/welcome.js';
import HomeOffer from '../containers/vr/homeOffer.js';
import ActivityOffer from '../containers/vr/activityOffer.js';
import Gallery from '../containers/vrGallery.js';
import HappeningComponent from '../containers/kids/happeningComponent.js';
import ContactFrom from '../containers/contactForm.js';
import AnimatedButton from '../components/animatedButton.js';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import {
    Container, Row, Col
} from 'reactstrap';


import image1 from '../images/happening/image1.jpg';
import image2 from '../images/happening/image2.jpg';


import LinesLeft from '../images/vr/linesLeft.png';
import RinesRight from '../images/vr/linesRight.png';

export class vr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }


    componentDidMount(){
        this.props.setType('vr');
        const API_KEY = "IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo";
    
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + API_KEY
        }

        axios.get(`https://euroexpress.tips:4001/api/v1/pub/article_all`)
            .then(res => {
                let  data = res.data;
                data = data.slice(-2)
                this.setState({ data });
            })

    }

    render() {

        const buttonText = "Pogledaj kompletnu galeriju";
        const buttonText2 = "Pogledaj sva dešavanja";

        return (
            <div>
                <Carousel />
                <Welcome />
                <HomeOffer />
                <ActivityOffer />

                <div className="homeGallery vr">
                    <Container>
                        <Row>
                            <div className="title">
                                <h1>Galerija</h1>
                                <p>{/*----*/}</p>
                            </div>
                        </Row>
                        <Gallery />
                        <div className="homeButtonWrapper">
                            <Link to="/galerija"><AnimatedButton buttonText={buttonText} /></Link>
                        </div>
                    </Container>
                </div>

                <div className="happening">
                    <div className="lines">
                        <img src={LinesLeft} className="linesLeft" />
                        <img src={RinesRight} className="rinesRight" />
                    </div>
                    <Container>
                        <h1>Dešavanja</h1>
                        <p>{/*----*/}</p>
                        <Row>
                        {this.state.data.map((items, idx) => {
                            const ENDPOINT = "https://euroexpress.tips:4002";
                            const IMAGES = ENDPOINT + "/images/image_get/";
                            return (
                                <Col xl="6" lg="6" md="6" xs="12" key={items.id}>
                                    <div className="boxHappening">
                                        <div className="contentHappening">
                                        <Link to={`/desavanja/${this.props.type}/${items.id}`}>
                                                <h5>{items.title}</h5></Link>
                                                <h6>{moment.unix(items.timestamp).format('DD.MM.YYYY HH:mm') }</h6>
                                            <p>{items.text}</p>
                                        </div>
                                        <div className="image">
                                            <Link to={`/desavanja/${this.props.type}/${items.id}`}>
                                                <img src={IMAGES + items.banner.url} className="img-fluid" />
                                            </Link>
                                        </div>
                                    </div>
                                </Col>

                            )
                        })}
                        </Row>

                        <Link to={`/desavanja/${this.props.type}`}><AnimatedButton buttonText={buttonText2} /></Link>
                    </Container>
                </div>
                <ContactFrom />
            </div>
        )
    }
}

export default DefaultLayout(vr)
