import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import DefaultLayout from '../../containers/defaultLayout.js';
import detailImage from '../../images/detailImage.png';
import HappeningComponent from '../../containers/kids/happeningComponent.js';
import {
    Container
} from 'reactstrap';

import yellowBalloon from '../../images/animation/yellowBalloon.png';
import star from '../../images/animation/star.png';
import blueBalloon from '../../images/animation/blueBalloon.png';
import redBallonRight from '../../images/animation/redBallonRight.png';
import doubleStars from '../../images/animation/doubleStars.png';
import moment from 'moment';



import image1 from '../../images/happening/image1.jpg';
import image2 from '../../images/happening/image2.jpg';

const jsonDesavanja = [
    {
        _id: 1,
        title: 'Novo u Banjoj Luci!',
        date: '05.08.2019',
        desc: 'Lorem ipsum dolor sit amet, consetetur  sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.',
        image: image1,
        link: '/desavanja-detaljno'
    },
    {
        _id: 2,
        title: 'Novo u Banjoj Luci!',
        date: '05.08.2019',
        desc: 'Lorem ipsum dolor sit amet, consetetur  sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.',
        image: image2,
        link: '/desavanja-detaljno'
    },
    {
        _id: 3,
        title: 'Novo u Banjoj Luci!',
        date: '05.08.2019',
        desc: 'Lorem ipsum dolor sit amet, consetetur  sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.',
        image: image2,
        link: '/desavanja-detaljno'
    },
    {
        _id: 4,
        title: 'Novo u Banjoj Luci!',
        date: '05.08.2019',
        desc: 'Lorem ipsum dolor sit amet, consetetur  sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.',
        image: image2,
        link: '/desavanja-detaljno'
    },
    {
        _id: 5,
        title: 'Novo u Banjoj Luci!',
        date: '05.08.2019',
        desc: 'Lorem ipsum dolor sit amet, consetetur  sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.',
        image: image2,
        link: '/desavanja-detaljno'
    },
    {
        _id: 6,
        title: 'Novo u Banjoj Luci!',
        date: '05.08.2019',
        desc: 'Lorem ipsum dolor sit amet, consetetur  sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.',
        image: image2,
        link: '/desavanja-detaljno'
    }
]

export class happeningDetail extends Component {

    constructor(props){
        super(props);
        this.state = {

        }
    }

    componentWillMount(){
        window.scroll(0,0);
    }

    componentDidMount(){

        let url;

        
        if (this.props[0].match.params.type == 'kids'){
            url = 'https://euroexpress.tips:4002/api/v1/pub/article_get?id=';
        }else if (this.props[0].match.params.type == 'vr'){
            url = 'https://euroexpress.tips:4001/api/v1/pub/article_get?id=';
        }else if (this.props[0].match.params.type == 'laser'){
            url = 'https://euroexpress.tips:4003/api/v1/pub/article_get?id=';
        }

        fetch(`${url}${this.props[0].match.params.id}`, {
            headers: {
                Accept: 'application/json, text/plain, */*',
                Authorization: 'Bearer IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo'
            }
        }).then((res) => res.json()).then((result) => {
            this.setState(result);
        })
    }

    render() {
        console.log(this.state)
        return (
            <div className="detail">
                           <div className="animatedBalloon">
                    <img src={yellowBalloon} className="balloon" />
                    <img src={star} className="star" />
                    <img src={blueBalloon} className="balloon blueBalloon"/>
                </div>

                <div className="animatedBalloonRight">
                        <img src={redBallonRight} className="balloonRight" />
                        <img src={doubleStars} className="starRight" />
                    </div>
                <Container>
                <div className="breadcrumbs">
                        <p><Link to="/">Početna </Link>  O nama</p>
                    </div>

                    <div className="contentDetail">
                        <h1>{this.state.title}</h1>
                        <h6>{moment.unix(this.state.timestamp).format('DD. MMMM YYYY - HH:mm')}h </h6>

                        <img src={`https://euroexpress.tips:4002/images/image_get/${this.state.banner && this.state.banner.url}`} className="img-fluid"/>
                        <br/>
                        <p>
                        <div dangerouslySetInnerHTML={{__html: this.state.text}}>
                        </div>
                        </p>
                    </div>

                    {/*<HappeningComponent items={jsonDesavanja.slice(0,2)}/>*/}
                </Container>
            </div>
        )
    }
}

export default DefaultLayout(happeningDetail)
