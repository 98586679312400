import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import AnimatedButton from '../../components/animatedButton.js';


import {
    Container, Row, Col, Button
} from 'reactstrap';


import balon from '../../images/bridthey/balon.png';
import torta from '../../images/bridthey/torta.png';
import anim1 from '../../images/bridthey/anim1.png';
import stars from '../../images/bridthey/stars.png';
import dekoracija from '../../images/bridthey/dekoracija.png';



import redBalloon from '../../images/animation/redBallon.png';
import starRed from '../../images/animation/starRed.png';
import purpleBalloon from '../../images/animation/purpleBalloon.png';
import star from '../../images/animation/star.png';

export class birthdayHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animation: 'item1',
            items: [],
            selected: null
        }
    }

    componentDidMount(){
        fetch('https://euroexpress.tips:4002/api/v1/pub/offers_get', {
            headers: {
                Authorization: 'Bearer IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo',
                Accept: 'application/json, text/plain, */*'
            }
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            result.sort((a,b) => (a.originalPrice > b.originalPrice) ? 1 : ((b.originalPrice > a.originalPrice) ? -1 : 0)); 

            this.setState({
                items: result,
                selected: result.length ? result[0].id : null
            })
        })

    }

    render() {
        const buttonText = "25 Osoba";
        const buttonText2 = "35 Osoba";
        const buttonText3 = "45 Osoba";
        const buttonText4 = "Rezerviši";
        return (
            <div className="birthdayHome">
                 <div className="animatedBalloon">
                    <img src={starRed} className="balloon" />
                    <img src={redBalloon} className="star" />
                </div>

                <div className="animatedBalloonRight">
                <img src={purpleBalloon} className="starRight" />
                        <img src={star} className="balloonRight" />
                        
                    </div>
                <Container>
                    <Row>
                        <Col xl="5" lg="5" md="5">
                            <div className="content">
                                <h1>Rođendani</h1>
                                <p>Igraonica „Wunderland“ organizuje proslave
                                dječijih rođendana za sve uzraste. Dok djeca
                                provode nezaboravne rođendanske trenutke
                                u igraonici, za roditelje smo pripremili
                                adekvatan prostor gdje uz konzumaciju hrane
                                i pića mogu pratiti proslavu i neometano
                                uživati. Za sve informacije kontaktirajte nas na 065 006 800</p>
                                
                                {
                                    this.state.items.map((item, idx) => {
                                        return (
                                            <div className={this.state.animation == `item${idx+1}` ? "color1" : ""} onClick={() => {
                                                this.setState({ animation: `item${idx+1}`, selected: item.id });
                                            }}>
                                                <AnimatedButton buttonText={item.title} />
                                            </div>
            
                                        )
                                    })
                                }
                                

                                <div className="wrapperButton">
                                    <Link to={`/rodjendani/rezervacija/${this.state.selected}`}> <AnimatedButton buttonText={buttonText4} /></Link>
                                </div>
                            </div>
                        </Col>


                        <Col xl="6" lg="6" md="6" className="offset-xl-1 offset-lg-1 offset-md-1">

                            <div className="wrapper">
                                <img src={balon} className="img-fluid balon" />

                                <div className="boxAnimation">

                                    <img src={torta} className={this.state.animation == 'item1' ? "img fluid torta " : this.state.animation == 'item2' ? "animation torta" : this.state.animation == 'item3' ? " torta animation35" : ""} />


                                    <img src={stars} className={this.state.animation == 'item2' ? "img-fluid stars starsAnimation" : this.state.animation == 'item3' ? "stars starsAnimation2" : this.state.animation == 'item1' ? "stars" : ""} />

                                    <img src={anim1} className={this.state.animation == 'item3' ? "img-fluid animatedContent animated" : "img-fluid animatedContent"} />

                                    <img src={dekoracija} className={this.state.animation == 'item3' || this.state.animation == 'item2' ? "img-fluid animatedContent animated" : "img-fluid animatedContent"} />
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default birthdayHome
