import React, { Component } from 'react'
import laser1 from '../images/gallery/laser/(1).jpg';
import laser2 from '../images/gallery/laser/(2).jpg';
import laser3 from '../images/gallery/laser/(3).jpg';
import laser4 from '../images/gallery/laser/(4).jpg';
import laser5 from '../images/gallery/laser/(5).jpg';
import laser6 from '../images/gallery/laser/(6).jpg';

import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';

const pictures = [
    
    {
        src: laser1,
        altText: 'Picture1',
        caption: 'Picture1'
    },


    {
        src: laser2,
        altText: 'Picture2',
        caption: 'Picture2'
    },


    {
        src: laser3,
        altText: 'Picture3',
        caption: 'Picture3'
    },


    {
        src: laser4,
        altText: 'Picture4',
        caption: 'Picture4'
    },


    {
        src: laser5,
        altText: 'Picture5',
        caption: 'Picture5'
    },


    {
        src: laser6,
        altText: 'Picture6',
        caption: 'Picture6'
    },



];

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,

        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === pictures.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? pictures.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {

        const activeIndex = this.state.activeIndex;
        const slides = pictures.map((item, idx) => {
            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <img src={item.src} className="img-fluid" />

                </CarouselItem>
            )
        });

        return (
            <>
                <div className="galleryPictures">
                    <Row>
                      
                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 0 })} >
                                <img src={laser1} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 1 })} >
                                <img src={laser2} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 2 })} >
                                <img src={laser3} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 3 })} >
                                <img src={laser4} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 4 })} >
                                <img src={laser5} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 67 })} >
                                <img src={laser6} className="img-fluid" />
                            </div>
                        </Col>


                    </Row>
                </div>
                {this.state.opened ?
                    <div className="lightbox">
                        <Container>
                            <Row>
                                <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                <Carousel className="bg"
                                    activeIndex={activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                >
                                    <CarouselIndicators items={pictures} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                    {slides}
                                    <i className="fa fa-angle-left" onClick={this.previous}></i>
                                    <i className="fa fa-angle-right" onClick={this.next}></i>
                                </Carousel>
                            </Row>
                        </Container>
                    </div> : null
                }
            </>


        )
    }
}

export default Gallery
