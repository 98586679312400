import React, { Component } from 'react'
import AnimtedButton from '../../components/animatedButton.js';
import WelcomeImage from '../../images/welcomeImageVr.png';


import {
    Container,
    Row,
    Col
} from 'reactstrap';

import LinesLeft from '../../images/vr/linesLeft.png';
import RinesRight from '../../images/vr/linesRight.png';


class Welcome extends Component {
    render() {
        const buttonText = "Detaljnije"
        return (

            <div className="welcomeSection">
                <div className="lines">
                    <img src={LinesLeft} className="linesLeft"/>
                    <img src={RinesRight} className="rinesRight"/>
                </div>
                <Container>
                    <Row>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <h1>Dobro došli u Wunderland!</h1>
                            <p>Wunderland predstavlja do sada neviđeni
                            koncept igre i zabave u Banjaluci. Smještena u centru
                            grada na preko 2000m², ova igraonica nudi raznovrsne
                            sadržaje prilagođene kako djeci i mladima tako i
                            odraslima koji su spremni da se upuste u svijet Virtual
                        reality avantura.</p>
                            <AnimtedButton buttonText={buttonText} />
                        </Col>
                        <Col xl="5" lg="5" md="6" xs="12" className="offset-xl-1 offset-lg-1">
                            <img src={WelcomeImage} className="img-fluid img"></img>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Welcome;
