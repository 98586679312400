import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import {
    Container, Row, Col, NavItem, NavLink
} from 'reactstrap';

import logo from '../images/svg/logo.svg';
import face from '../images/svg/facebook.svg';
import insta from '../images/svg/instagram.svg';
import pin from '../images/svg/pin.svg';
import phone from '../images/svg/phoneFooter.svg';
import mail from '../images/svg/mail.svg';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <Container>
                    <Row>
                        <Col xl="3" lg="3" md="3" xs="12">
                            <div className="logo">
                                <Isvg src={logo} />
                            </div>
                            <p>Lorem ipsum dolor sit amet,
                            consetetur sadipscing elitr, sed
                            diam nonumy eirmod tempor.</p>

                            <div className="socialNetwork">
                                <a href="https://www.facebook.com/wunderland.banjaluka/" target="_blank">  <Isvg src={face} /></a>
                                <a href="https://www.instagram.com/wunderland.banjaluka/" target="_blank">   <Isvg src={insta} /> </a>
                            </div>
                        </Col>

                        <Col xl="2" lg="2" md="2" xs="4" className="offset-xl-1 offset-lg-1 offset-md-1">


                            <div className="footerMenu">
                                <h2>NAVIGACIJA</h2>
                                <ul>
                                    <li><Link to="/">Početna </Link></li>
                                    <li><Link to="/o-nama">O nama </Link></li>
                                    <li><Link to="/ponude">Ponude </Link></li>
                                    <li><Link to="/rodjendani">Rođendani </Link></li>
                                </ul>
                            </div>

                        </Col>

                        <Col xl="2" lg="2" md="2" xs="4">

                            <div className="footerMenu sec">

                                <ul>
                                    <li><Link to={`/desavanja/${this.props.type}`}>Dešavanja </Link></li>
                                    <li><Link to="/galerija">Galerija </Link></li>
                                    <li><Link to="/kontakt">Kontakt </Link></li>
                                </ul>
                            </div>
                        </Col>

                        <Col xl="4" lg="4" md="4">
                            <div className="contact">
                                <h2>KONTAKT</h2>
                                <ul>
                                    <li><Isvg src={phone} /> Jovana Dučića 25, 78000
                                   <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Banja Luka </li>

                                    <li><Isvg src={mail} />+387 65 006 800  </li>

                                    <li><Isvg src={pin} /> info@wunderland.ba </li>
                                </ul>

                            </div>

                        </Col>
                        <Col xl="12" xs="12" lg="12" md="12">
                            <div className="copyRight">
                                <p>Copyright © Wunderland - 2019. All Rights Reserved.</p>
                                <p>Created by <span><a href="https://novamedia.agency" target="_blank">NOVA media</a></span></p>
                            </div>
                        </Col>
                    </Row>

                </Container>

            </div>
        )
    }
}

export default Footer;
