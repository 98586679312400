import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import slide1 from '../../images/slides/kids/1.png';
import slide2 from '../../images/slides/kids/2.png';
import slide3 from '../../images/slides/kids/3.png';

import AnimtedButton from '../../components/animatedButton.js';

import {
    Container,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Col,
    Row
} from 'reactstrap';

const items = [
    {
        src: slide1,
        title: 'Novo u Banjoj Luci',
        caption: 'Baby corner',
        desc: 'Wunderland igraonica je odličan izbor za igru i zabavu za djecu različitih uzrasta!⁣',
        buttonText: 'Rezerviši',
        link: '/rodjendani',
    },
    {
        src: slide2,
        title: 'Novo u Banjoj Luci',
        caption: 'Veliki izbor igara za sve uzraste',
        desc: 'Wunderland VR je najveća zona virtualne realnosti na Balkanu koja je opremljena najsavremenijom VR tehnologijom. ⁣',
        buttonText: 'Rezerviši',
        link: '/rodjendani'
    },
    {
        src: slide3,
        title: 'Novo u Banjoj Luci',
        caption: 'Dječija igraonica Wunderland',
        desc: 'Naša arena je jedinstven prostor za igru opremljen najmodernijom tehnologijom koji je prilagođen kako djeci tako i odraslima. ⁣',
        buttonText: 'Rezerviši',
        link: '/rodjendani'
    }
];

class Example extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {

        const buttonText = "Rezerviši";

        const { activeIndex } = this.state;

        const slides = items.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}>


                    <img src={item.src} alt={item.altText} />


                    <div className="text">
                        <h4>{item.title}</h4>
                        <h2>{item.caption}</h2>
                        <p dangerouslySetInnerHTML={{ __html: item.desc }}></p>

                        <Link to={item.link}><AnimtedButton buttonText={item.buttonText} /></Link>
                    </div>


                </CarouselItem>
            );
        });

        return (
            <div className="slider">
                <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous}>

                    {slides}

                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
            </div>
        );
    }
}


export default Example;