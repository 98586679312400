import React, { Component } from 'react'
import kids1 from '../../images/gallery/kids/(1).jpg';
import kids2 from '../../images/gallery/kids/(2).jpg';
import kids3 from '../../images/gallery/kids/(3).jpg';
import kids4 from '../../images/gallery/kids/(4).jpg';
import kids5 from '../../images/gallery/kids/(5).jpg';
import kids6 from '../../images/gallery/kids/(6).jpg';
import kids7 from '../../images/gallery/kids/(7).jpg';
import kids8 from '../../images/gallery/kids/(8).jpg';
import kids9 from '../../images/gallery/kids/(9).jpg';
import kids10 from '../../images/gallery/kids/(10).jpg';
import kids11 from '../../images/gallery/kids/(11).jpg';
import kids12 from '../../images/gallery/kids/(12).jpg';
import kids13 from '../../images/gallery/kids/(13).jpg';
import kids14 from '../../images/gallery/kids/(14).jpg';
import kids15 from '../../images/gallery/kids/(15).jpg';
import kids16 from '../../images/gallery/kids/(16).jpg';
import kids17 from '../../images/gallery/kids/(17).jpg';
import kids18 from '../../images/gallery/kids/(18).jpg';
import kids19 from '../../images/gallery/kids/(19).jpg';
import kids20 from '../../images/gallery/kids/(20).jpg';
import kids21 from '../../images/gallery/kids/(21).jpg';
import kids22 from '../../images/gallery/kids/(22).jpg';
import kids23 from '../../images/gallery/kids/(23).jpg';
import kids24 from '../../images/gallery/kids/(24).jpg';
import kids25 from '../../images/gallery/kids/(25).jpg';
import kids26 from '../../images/gallery/kids/(26).jpg';
import kids27 from '../../images/gallery/kids/(27).jpg';
import kids28 from '../../images/gallery/kids/(28).jpg';
import kids29 from '../../images/gallery/kids/(29).jpg';
import kids30 from '../../images/gallery/kids/(30).jpg';
import kids31 from '../../images/gallery/kids/(31).jpg';
import kids32 from '../../images/gallery/kids/(32).jpg';
import kids33 from '../../images/gallery/kids/(33).jpg';
import kids34 from '../../images/gallery/kids/(34).jpg';
import kids35 from '../../images/gallery/kids/(35).jpg';
import kids36 from '../../images/gallery/kids/(36).jpg';
import kids37 from '../../images/gallery/kids/(37).jpg';
import kids38 from '../../images/gallery/kids/(38).jpg';
import kids39 from '../../images/gallery/kids/(39).jpg';
import kids40 from '../../images/gallery/kids/(40).jpg';
import kids41 from '../../images/gallery/kids/(41).jpg';
import kids42 from '../../images/gallery/kids/(42).jpg';
import kids43 from '../../images/gallery/kids/(43).jpg';
import kids44 from '../../images/gallery/kids/(44).jpg';
import vr1 from '../../images/gallery/vr/(1).jpg';
import vr2 from '../../images/gallery/vr/(2).jpg';
import vr3 from '../../images/gallery/vr/(3).jpg';
import vr4 from '../../images/gallery/vr/(4).jpg';
import vr5 from '../../images/gallery/vr/(5).jpg';
import vr6 from '../../images/gallery/vr/(6).jpg';
import vr7 from '../../images/gallery/vr/(7).jpg';
import vr8 from '../../images/gallery/vr/(8).jpg';
import vr9 from '../../images/gallery/vr/(9).jpg';
import vr10 from '../../images/gallery/vr/(10).jpg';
import vr11 from '../../images/gallery/vr/(11).jpg';
import vr12 from '../../images/gallery/vr/(12).jpg';
import vr13 from '../../images/gallery/vr/(13).jpg';
import vr14 from '../../images/gallery/vr/(14).jpg';
import vr15 from '../../images/gallery/vr/(15).jpg';
import vr16 from '../../images/gallery/vr/(16).jpg';
import vr17 from '../../images/gallery/vr/(17).jpg';
import vr18 from '../../images/gallery/vr/(18).jpg';
import laser1 from '../../images/gallery/laser/(1).jpg';
import laser2 from '../../images/gallery/laser/(2).jpg';
import laser3 from '../../images/gallery/laser/(3).jpg';
import laser4 from '../../images/gallery/laser/(4).jpg';
import laser5 from '../../images/gallery/laser/(5).jpg';
import laser6 from '../../images/gallery/laser/(6).jpg';
import laser7 from '../../images/gallery/laser/(7).jpg';
import laser8 from '../../images/gallery/laser/(8).jpg';
import laser9 from '../../images/gallery/laser/(9).jpg';
import laser10 from '../../images/gallery/laser/(10).jpg';
import laser11 from '../../images/gallery/laser/(11).jpg';
import laser12 from '../../images/gallery/laser/(12).jpg';
import laser13 from '../../images/gallery/laser/(13).jpg';
import laser14 from '../../images/gallery/laser/(14).jpg';
import laser15 from '../../images/gallery/laser/(15).jpg';
import laser16 from '../../images/gallery/laser/(16).jpg';

import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';

const pictures = [
    {
        src: kids1,
        altText: 'Picture1',
        caption: 'Picture1'
    },


    {
        src: kids2,
        altText: 'Picture2',
        caption: 'Picture2'
    },


    {
        src: kids3,
        altText: 'Picture3',
        caption: 'Picture3'
    },


    {
        src: kids4,
        altText: 'Picture4',
        caption: 'Picture4'
    },


    {
        src: kids5,
        altText: 'Picture5',
        caption: 'Picture5'
    },


    {
        src: kids6,
        altText: 'Picture6',
        caption: 'Picture6'
    },


    {
        src: kids7,
        altText: 'Picture7',
        caption: 'Picture7'
    },


    {
        src: kids8,
        altText: 'Picture8',
        caption: 'Picture8'
    },


    {
        src: kids9,
        altText: 'Picture9',
        caption: 'Picture9'
    },


    {
        src: kids10,
        altText: 'Picture10',
        caption: 'Picture10'
    },


    {
        src: kids11,
        altText: 'Picture11',
        caption: 'Picture11'
    },


    {
        src: kids12,
        altText: 'Picture12',
        caption: 'Picture12'
    },


    {
        src: kids13,
        altText: 'Picture13',
        caption: 'Picture13'
    },


    {
        src: kids14,
        altText: 'Picture14',
        caption: 'Picture14'
    },


    {
        src: kids15,
        altText: 'Picture15',
        caption: 'Picture15'
    },


    {
        src: kids16,
        altText: 'Picture16',
        caption: 'Picture16'
    },


    {
        src: kids17,
        altText: 'Picture17',
        caption: 'Picture17'
    },


    {
        src: kids18,
        altText: 'Picture18',
        caption: 'Picture18'
    },


    {
        src: kids19,
        altText: 'Picture19',
        caption: 'Picture19'
    },


    {
        src: kids20,
        altText: 'Picture20',
        caption: 'Picture20'
    },


    {
        src: kids21,
        altText: 'Picture21',
        caption: 'Picture21'
    },


    {
        src: kids22,
        altText: 'Picture22',
        caption: 'Picture22'
    },


    {
        src: kids23,
        altText: 'Picture23',
        caption: 'Picture23'
    },


    {
        src: kids24,
        altText: 'Picture24',
        caption: 'Picture24'
    },


    {
        src: kids25,
        altText: 'Picture25',
        caption: 'Picture25'
    },


    {
        src: kids26,
        altText: 'Picture26',
        caption: 'Picture26'
    },


    {
        src: kids27,
        altText: 'Picture27',
        caption: 'Picture27'
    },


    {
        src: kids28,
        altText: 'Picture28',
        caption: 'Picture28'
    },


    {
        src: kids29,
        altText: 'Picture29',
        caption: 'Picture29'
    },


    {
        src: kids30,
        altText: 'Picture30',
        caption: 'Picture30'
    },


    {
        src: kids31,
        altText: 'Picture31',
        caption: 'Picture31'
    },


    {
        src: kids32,
        altText: 'Picture32',
        caption: 'Picture32'
    },


    {
        src: kids33,
        altText: 'Picture33',
        caption: 'Picture33'
    },


    {
        src: kids34,
        altText: 'Picture34',
        caption: 'Picture34'
    },


    {
        src: kids35,
        altText: 'Picture35',
        caption: 'Picture35'
    },


    {
        src: kids36,
        altText: 'Picture36',
        caption: 'Picture36'
    },


    {
        src: kids37,
        altText: 'Picture37',
        caption: 'Picture37'
    },


    {
        src: kids38,
        altText: 'Picture38',
        caption: 'Picture38'
    },


    {
        src: kids39,
        altText: 'Picture39',
        caption: 'Picture39'
    },


    {
        src: kids40,
        altText: 'Picture40',
        caption: 'Picture40'
    },


    {
        src: kids41,
        altText: 'Picture41',
        caption: 'Picture41'
    },


    {
        src: kids42,
        altText: 'Picture42',
        caption: 'Picture42'
    },


    {
        src: kids43,
        altText: 'Picture43',
        caption: 'Picture43'
    },


    {
        src: kids44,
        altText: 'Picture44',
        caption: 'Picture44'
    },
    {
        src: vr1,
        altText: 'Picture1',
        caption: 'Picture1'
    },


    {
        src: vr2,
        altText: 'Picture2',
        caption: 'Picture2'
    },


    {
        src: vr3,
        altText: 'Picture3',
        caption: 'Picture3'
    },


    {
        src: vr4,
        altText: 'Picture4',
        caption: 'Picture4'
    },


    {
        src: vr5,
        altText: 'Picture5',
        caption: 'Picture5'
    },


    {
        src: vr6,
        altText: 'Picture6',
        caption: 'Picture6'
    },


    {
        src: vr7,
        altText: 'Picture7',
        caption: 'Picture7'
    },


    {
        src: vr8,
        altText: 'Picture8',
        caption: 'Picture8'
    },


    {
        src: vr9,
        altText: 'Picture9',
        caption: 'Picture9'
    },


    {
        src: vr10,
        altText: 'Picture10',
        caption: 'Picture10'
    },


    {
        src: vr11,
        altText: 'Picture11',
        caption: 'Picture11'
    },


    {
        src: vr12,
        altText: 'Picture12',
        caption: 'Picture12'
    },


    {
        src: vr13,
        altText: 'Picture13',
        caption: 'Picture13'
    },


    {
        src: vr14,
        altText: 'Picture14',
        caption: 'Picture14'
    },


    {
        src: vr15,
        altText: 'Picture15',
        caption: 'Picture15'
    },


    {
        src: vr16,
        altText: 'Picture16',
        caption: 'Picture16'
    },


    {
        src: vr17,
        altText: 'Picture17',
        caption: 'Picture17'
    },


    {
        src: vr18,
        altText: 'Picture18',
        caption: 'Picture18'
    },
    {
        src: laser1,
        altText: 'Picture1',
        caption: 'Picture1'
    },


    {
        src: laser2,
        altText: 'Picture2',
        caption: 'Picture2'
    },


    {
        src: laser3,
        altText: 'Picture3',
        caption: 'Picture3'
    },


    {
        src: laser4,
        altText: 'Picture4',
        caption: 'Picture4'
    },


    {
        src: laser5,
        altText: 'Picture5',
        caption: 'Picture5'
    },


    {
        src: laser6,
        altText: 'Picture6',
        caption: 'Picture6'
    },


    {
        src: laser7,
        altText: 'Picture7',
        caption: 'Picture7'
    },


    {
        src: laser8,
        altText: 'Picture8',
        caption: 'Picture8'
    },


    {
        src: laser9,
        altText: 'Picture9',
        caption: 'Picture9'
    },


    {
        src: laser10,
        altText: 'Picture10',
        caption: 'Picture10'
    },


    {
        src: laser11,
        altText: 'Picture11',
        caption: 'Picture11'
    },


    {
        src: laser12,
        altText: 'Picture12',
        caption: 'Picture12'
    },


    {
        src: laser13,
        altText: 'Picture13',
        caption: 'Picture13'
    },


    {
        src: laser14,
        altText: 'Picture14',
        caption: 'Picture14'
    },


    {
        src: laser15,
        altText: 'Picture15',
        caption: 'Picture15'
    },


    {
        src: laser16,
        altText: 'Picture16',
        caption: 'Picture16'
    },

];

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,

        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === pictures.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? pictures.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {

        const activeIndex = this.state.activeIndex;
        const slides = pictures.map((item, idx) => {
            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <img src={item.src} className="img-fluid" />

                </CarouselItem>
            )
        });

        return (
            <>
                <div className="galleryPictures">
                    <Row>
                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 0 })} >
                                <img src={kids1} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 1 })} >
                                <img src={kids2} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 2 })} >
                                <img src={kids3} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 3 })} >
                                <img src={kids4} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 4 })} >
                                <img src={kids5} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 5 })} >
                                <img src={kids6} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 6 })} >
                                <img src={kids7} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 7 })} >
                                <img src={kids8} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 8 })} >
                                <img src={kids9} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 9 })} >
                                <img src={kids10} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 10 })} >
                                <img src={kids11} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 11 })} >
                                <img src={kids12} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 12 })} >
                                <img src={kids13} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 13 })} >
                                <img src={kids14} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 14 })} >
                                <img src={kids15} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 15 })} >
                                <img src={kids16} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 16 })} >
                                <img src={kids17} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 17 })} >
                                <img src={kids18} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 18 })} >
                                <img src={kids19} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 19 })} >
                                <img src={kids20} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 20 })} >
                                <img src={kids21} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 21 })} >
                                <img src={kids22} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 22 })} >
                                <img src={kids23} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 23 })} >
                                <img src={kids24} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 24 })} >
                                <img src={kids25} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 25 })} >
                                <img src={kids26} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 26 })} >
                                <img src={kids27} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 27 })} >
                                <img src={kids28} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 28 })} >
                                <img src={kids29} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 29 })} >
                                <img src={kids30} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 30 })} >
                                <img src={kids31} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 31 })} >
                                <img src={kids32} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 32 })} >
                                <img src={kids33} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 33 })} >
                                <img src={kids34} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 34 })} >
                                <img src={kids35} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 35 })} >
                                <img src={kids36} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 36 })} >
                                <img src={kids37} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 37 })} >
                                <img src={kids38} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 38 })} >
                                <img src={kids39} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 39 })} >
                                <img src={kids40} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 40 })} >
                                <img src={kids41} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 41 })} >
                                <img src={kids42} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 42 })} >
                                <img src={kids43} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 43 })} >
                                <img src={kids44} className="img-fluid" />
                            </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 44 })} >
                                <img src={vr1} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 45 })} >
                                <img src={vr2} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 46 })} >
                                <img src={vr3} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 47 })} >
                                <img src={vr4} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 48 })} >
                                <img src={vr5} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 49 })} >
                                <img src={vr6} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 50 })} >
                                <img src={vr7} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 51 })} >
                                <img src={vr8} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 52 })} >
                                <img src={vr9} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 53 })} >
                                <img src={vr10} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 54 })} >
                                <img src={vr11} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 55 })} >
                                <img src={vr12} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 56 })} >
                                <img src={vr13} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 57 })} >
                                <img src={vr14} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 58 })} >
                                <img src={vr15} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 59 })} >
                                <img src={vr16} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 60 })} >
                                <img src={vr17} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 61 })} >
                                <img src={vr18} className="img-fluid" />
                            </div>
                        </Col>
                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 62 })} >
                                <img src={laser1} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 63 })} >
                                <img src={laser2} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 64 })} >
                                <img src={laser3} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 65 })} >
                                <img src={laser4} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 66 })} >
                                <img src={laser5} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 67 })} >
                                <img src={laser6} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 68 })} >
                                <img src={laser7} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 69 })} >
                                <img src={laser8} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 70 })} >
                                <img src={laser9} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 71 })} >
                                <img src={laser10} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 72 })} >
                                <img src={laser11} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 73 })} >
                                <img src={laser12} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 74 })} >
                                <img src={laser13} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 75 })} >
                                <img src={laser14} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 76 })} >
                                <img src={laser15} className="img-fluid" />
                            </div>
                        </Col>


                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 77 })} >
                                <img src={laser16} className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </div>
                {this.state.opened ?
                    <div className="lightbox">
                        <Container>
                            <Row>
                                <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                <Carousel className="bg"
                                    activeIndex={activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                >
                                    <CarouselIndicators items={pictures} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                    {slides}
                                    <i className="fa fa-angle-left" onClick={this.previous}></i>
                                    <i className="fa fa-angle-right" onClick={this.next}></i>
                                </Carousel>
                            </Row>
                        </Container>
                    </div> : null
                }
            </>


        )
    }
}

export default Gallery
