import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../../containers/defaultLayout.js';
import AnimatedButton from '../../components/animatedButton';
import ContactForm from '../../containers/contactForm.js';
import {
    Container, Row, Col
} from 'reactstrap';
import image9D from '../../images/image9D.png';
import balloon from '../../images/animation/yellowBalloon.png';
import star from '../../images/animation/star.png';
import doubleStars from '../../images/animation/doubleStars.png'
import axios from 'axios';

export class dVirtual extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {
                categories: [],
            },
            items: []
          

        }
    }


    componentWillMount() {
        window.scroll(0, 0);
    }

    componentDidMount() {
        const API_KEY = "IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo";

        axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + API_KEY
        }


        let url;

        if (this.props[0].match.params.type == 'kids'){
            url = 'https://euroexpress.tips:4002/api/v1/pub/get_menu';
        }else if (this.props[0].match.params.type == 'vr'){
            url = 'https://euroexpress.tips:4001/api/v1/pub/get_menu';
        }else if (this.props[0].match.params.type == 'laser'){
            url = 'https://euroexpress.tips:4003/api/v1/pub/get_menu';
        }


        axios.get(url)
            .then(res => {
                const data = res.data;
                this.setState({ data });
            })


    }

    render() {
        let asd = {};
        console.log(this.state)
        let podaci = this.state.data.categories;
        for (let i = 0; i < podaci.length; i++) {
            if (podaci[i].id == this.props[0].match.params.id) {
                 asd = podaci[i];
            }
        }

        console.log(asd);

        const buttonText = asd.items && asd.items[this.props[0].match.params.id2].preparationTime.toString() + "MIN" + " - " + asd.items[0].price.toString() + "KM";
        return (
            <>
                <div className="dVirtual">
                    <div className="animatedBalloon">
                        <img src={balloon} className="balloon" />
                        <img src={star} className="star" />
                    </div>

                    <div className="animatedBalloonRight">

                        <img src={doubleStars} className="starRight" />
                    </div>
                    <Container>
                        <div className="breadcrumbs">
                            <p><Link to="/">Početna </Link>  O nama</p>
                        </div>

                        <div className="dVirtualContent">
                            <h1>{asd.items && asd.items[this.props[0].match.params.id2].title}</h1>

                            <Row>
                                <Col xl="6" lg="6" md="6" xs="12">
                                    <div className="text">
                                        <h2>{asd.title}</h2>
                                        <AnimatedButton buttonText={buttonText} />
                                        <p>{asd.items && asd.items[this.props[0].match.params.id2].about}</p>
                                    </div>
                                </Col>


                                <Col xl="6" lg="6" md="6" xs="12">
                                    <img src={'https://euroexpress.tips:4002/images/image_get/' +  (asd.items && asd.items[this.props[0].match.params.id2] && asd.items[this.props[0].match.params.id2].image &&   asd.items[this.props[0].match.params.id2].image.url)} className="img-fluid" />
                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>
                <ContactForm />
            </>
        )
    }
}

export default DefaultLayout(dVirtual)
