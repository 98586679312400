import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import AnimatedButton from '../../components/animatedButton';
import Slider from "react-slick";
import ponudaPicture1 from '../../images/ponudaPicture1.png';
import ponudaPicture2 from '../../images/ponudaPicture2.png';
import ponudaPicture3 from '../../images/ponudaPicture3.png';
import ponudaPicture4 from '../../images/ponudaPicture4.png';
import ponudaPicture5 from '../../images/ponudaPicture5.png';
import ponudaPicture6 from '../../images/ponudaPicture6.png';
import axios from 'axios';

import {
    Container, Row, Col
} from 'reactstrap';

export class activityOffer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            kids: [],
            laser: [],
            vr: [],
            selected: null
        }
    }

    componentDidMount() {

        const API_KEY = "IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo";

        axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + API_KEY
        }

        axios.get('https://euroexpress.tips:4002/api/v1/pub/get_menu')
            .then(res => {
                const data = res.data;
                this.setState({ kids: data.categories ? data.categories : [] });
            })
        axios.get('https://euroexpress.tips:4001/api/v1/pub/get_menu')
            .then(res => {
                const data = res.data;
                this.setState({ vr: data.categories ? data.categories : [] });
            })

        axios.get('https://euroexpress.tips:4003/api/v1/pub/get_menu')
            .then(res => {
                const data = res.data;
                this.setState({ laser: data.categories ? data.categories : [] });
            })


    }

    render() {
        const buttonText = "Sve";
        const buttonText2 = "Kids";
        const buttonText3 = "VR";
        const buttonText4 = "Laser Tag";
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoPlay: false


        };

        console.log(this.state.kids);

        let allItems = {};


        if (!this.state.selected || this.state.selected == 'kids') {
            for (let i = 0; i < this.state.kids.length; i++) {
                for (let j = 0; j < this.state.kids[i].items.length; j++) {
                    this.state.kids[i].items[j].url = '/ponude/kids/' + this.state.kids[i].id + '/' + j;
                    this.state.kids[i].items[j].kids = true;
                    allItems[this.state.kids[i].items[j].title] = this.state.kids[i].items[j];
                }
            }
        }

        if (!this.state.selected || this.state.selected == 'laser') {

            for (let i = 0; i < this.state.laser.length; i++) {
                for (let j = 0; j < this.state.laser[i].items.length; j++) {
                    this.state.laser[i].items[j].laser = true;

                    this.state.laser[i].items[j].url = '/ponude/laser/' + this.state.laser[i].id + '/' + j;
                    allItems[this.state.laser[i].items[j].title] = this.state.laser[i].items[j];
                }
            }

        }

        if (!this.state.selected || this.state.selected == 'vr') {

            for (let i = 0; i < this.state.vr.length; i++) {
                for (let j = 0; j < this.state.vr[i].items.length; j++) {
                    this.state.vr[i].items[j].vr = true;

                    this.state.vr[i].items[j].url = '/ponude/vr/' + this.state.vr[i].id + '/' + j;
                    allItems[this.state.vr[i].items[j].title] = this.state.vr[i].items[j];
                }
            }
        }


        allItems = Object.values(allItems);

        var temparray = [], chunk = 6;
        for (let i = 0, j = allItems.length; i < j; i += chunk) {
            temparray.push(allItems.slice(i, i + chunk));
            // do whatever
        }

        return (
            <div className="activityOffer">
                <Container>
                    <Row>
                        <Col xl="12">
                            <h1>Ponuda aktivnosti</h1>
                            <p></p>
                        </Col>
                        <Col xl="12" lg="12" md="12" xs="12" className="multipleButtons">
                            <Row>
                                <Col xl="2" lg="2" md="2" xs="6">
                                    <AnimatedButton buttonText={buttonText} onClick={() => { this.setState({ selected: null }) }} />
                                </Col>
                                <Col xl="2" lg="2" md="2" xs="6">
                                    <AnimatedButton buttonText={buttonText2} onClick={() => { this.setState({ selected: 'kids' }) }} />
                                </Col>
                                <Col xl="2" lg="2" md="2" xs="6">
                                    <AnimatedButton buttonText={buttonText3} onClick={() => { this.setState({ selected: 'vr' }) }} />
                                </Col>
                                <Col xl="2" lg="2" md="2" xs="6">
                                    <AnimatedButton buttonText={buttonText4} onClick={() => { this.setState({ selected: 'laser' }) }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Slider {...settings}>
                            {temparray.map((items, idxI) => {
                                return (

                                    <div className="wrapper">
                                        {
                                            items.map((item, idx) => {

                                                if (item)
                                                    return (
                                                        <Col xl="4" lg="4" md="6" xs="6" key={idx}>
                                                            <Link to={item.url ? item.url : '/'}>
                                                                <div className="box">
                                                                    <img src={`https://euroexpress.tips:4002/images/image_get/${item.image && item.image.url}`} className="img-fluid"></img>
                                                                    <div className="textBox">
                                                                        <p>{item.title}</p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </Col>

                                                    )
                                            })
                                        }
                                    </div>

                                )
                            })
                            }

                        </Slider>
                    </Row>
                </Container>

            </div >
        )
    }
}

export default activityOffer
