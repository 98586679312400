import React, { Component } from 'react'
import KontaktForma from '../components/forms/contactForm';
import axios from 'axios';

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';

export class ContactFrom extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false
        }
    }

    onSubmit = (data) => {
        const API_KEY = "IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo";
        const url = "https://euroexpress.tips:4002/api/v1/pub/contact_form";
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + API_KEY
        }
        axios.post(url, data)
            .then(this.setState({ done: true }))
            .catch(err => console.log(err.data))
    }

    render() {
        return (
            <div className="contactForm">
                <Container>
                    <Row>
                        <Col xl="12">
                            <h1>Kontaktirajte nas</h1>
                            <p>{/*----*/}</p>
                        </Col>
                    </Row>
                    <KontaktForma onSubmit={this.onSubmit} />
                    {this.state.done ?
                        <Container>
                            <div class="alert alert-info mb-5 mt-5" role="alert">Vaša poruka je uspješno poslata!</div>
                        </Container> : null}
                </Container>
            </div>
        )
    }
}

export default ContactFrom
