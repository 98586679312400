import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import Logo from '../images/logo.png';
import { NavLink as RRNavLink } from 'react-router-dom';
import headerLocation from '../images/svg/headerLocation.svg';
import headerPhone from '../images/svg/headerPhone.svg';
import headerInstagram from '../images/svg/headerInstagram.svg';
import headerFacebook from '../images/svg/headerFacebook.svg';
import AnimatedButton from '../components/animatedButton';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    Collapse
} from 'reactstrap';

class Navigacija extends Component {
    constructor(props) {
        super(props)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = { width: 0, height: 0, isOpen: false, isTop: false };

    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY > 0;
            if (isTop !== this.state.isTop) {
                this.onScroll(isTop);
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    onScroll(isTop) {
        this.setState({ isTop });

    }



    render() {
        const buttonText = "Laser Tag";
        const buttonText2 = "VR";
        const buttonText3 = "Kids";


        return (
            <div className={this.state.isTop ? "spacerNavbar" : null}>
                <div className={this.state.isTop ? "fixed-top" : null}>
                    <div className="Navigacija">
                        <Link to='/'><img src={Logo} className="headerLogo" /></Link>
                        <div className="navigacijaSpacer">
                            <Container>
                                <Row>
                                    <Col xl="6" lg="5" md="6" className="headerInfo offset-xl-0 offset-lg-2 offset-md-2">
                                        <p><Isvg src={headerLocation} />Jovana Dučića 25, 78000 Banja Luka</p>
                                    </Col>
                                    <Col xl="6" lg="5" md="4" className="headerInfo">
                                        <p><Isvg src={headerPhone} />+387 65 006 800</p>
                                    </Col>

                                </Row>
                            </Container>
                            <div className="headerSocial">
                                <div>
                                    <p><a href="https://www.facebook.com/wunderland.banjaluka/" target="_blank"><Isvg src={headerFacebook} /></a>

                                        <a href="https://www.instagram.com/wunderland.banjaluka/" target="_blank"><Isvg src={headerInstagram} /></a>
                                        Pratite nas</p>
                                </div>
                            </div>
                            <div className="menuWrapper">
                                <Container>
                                    <Row>
                                        <Col xl="11" lg="9" md="8" xs="6" className="offset-xl-0 offset-lg-2">
                                            <div className={this.state.width > 991 ? "navbar navbar-expand-lg" : null}>
                                                <Collapse isOpen={this.state.isOpen} navbar>
                                                    <NavItem className="nav-item">
                                                        <NavLink exact tag={RRNavLink} className="nav-link" to="/" activeClassName="active">Početna</NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav-item">
                                                        <NavLink exact tag={RRNavLink} className="nav-link" to={this.props.type == 'vr' ? '/o-nama-vr' : this.props.type == 'laser' ? '/o-nama-laser' : '/o-nama'} activeClassName="active">O nama</NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav-item">
                                                        <NavLink exact tag={RRNavLink} className="nav-link" to={"/ponude/" + this.props.type} activeClassName="active">Ponude</NavLink>
                                                    </NavItem>
                                                    {this.props.type == 'kids' ?
                                                        <NavItem className="nav-item">
                                                            <NavLink exact tag={RRNavLink} className="nav-link" to="/rodjendani" activeClassName="active">Rođendani</NavLink>
                                                        </NavItem>
                                                        : null}
                                                    <NavItem className="nav-item">
                                                        <NavLink exact tag={RRNavLink} className="nav-link" to={"/desavanja/" + this.props.type} activeClassName="active">Dešavanja</NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav-item">
                                                        <NavLink exact tag={RRNavLink} className="nav-link" to="/galerija" activeClassName="active">Galerija</NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav-item">
                                                        <NavLink exact tag={RRNavLink} className="nav-link" to="/kontakt" activeClassName="active">Kontakt</NavLink>
                                                    </NavItem>
                                                </Collapse>
                                            </div>
                                        </Col>

                                    </Row>
                                </Container>
                                <div className="headerButtons">
                                    {/*<Link onClick={() => {
                                        if (this.props.type == 'laser')
                                            this.props.setType('kids');
                                        else
                                            this.props.setType('laser');

                                    }} to={this.props.type != 'laser' ? "/laser" : "/"}><AnimatedButton buttonText={this.props.type != 'laser' ? buttonText : buttonText3} /></Link>*/}
                                    <Link onClick={() => {
                                        if (this.props.type == 'vr')
                                            this.props.setType('kids');
                                        else
                                            this.props.setType('vr');

                                    }} to={this.props.type != 'vr' ? "/vr" : "/"}> <AnimatedButton buttonText={this.props.type != 'vr' ? buttonText2 : buttonText3} /></Link>
                                </div>
                            </div>
                            <button className="btn-menu" onClick={() => this.setState({ isOpen: !this.state.isOpen })}><i class="fa fa-align-justify"></i></button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navigacija;
