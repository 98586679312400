import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector  } from 'redux-form'
import { connect } from 'react-redux'

import Isvg from 'react-inlinesvg';
import showResults from '../../showResults';
import AnimatedButton from '../../components/animatedButton';

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';

import { renderTextField, renderTextareaField, renderDateField, renderTimeField, email, renderCheckField } from '../../components/fields/fields.js'
const required = value => value ? undefined : "Required"
//const required = value => null


//const optionsForm = (props) => {
class SelectingFormValuesForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: 0
        }
        //this.onSubmit = this.onSubmit.bind(this);


    }
    render() {
        const { handleSubmit, pristine, reset, submitting, dekoratori } = this.props;
        //console.log(this.props);
        //    console.log(this.props.decorators)

        let decoratorsPrice = 0;
        for(let i=0;i<this.props.decorators.length;i++){
            if ( this.props.decoratorsObj && this.props.decoratorsObj[ this.props.decorators[i].title] ){
                decoratorsPrice += this.props.decorators[i].price;
            }
        }

        let foodPrice = 0;
        for(let i=0;i<this.props.foodDecorators.length;i++){
            if ( this.props.foodObj && this.props.foodObj[ this.props.foodDecorators[i].title] ){
                foodPrice += this.props.foodDecorators[i].price;
            }
        }



        return (


            <form onSubmit={handleSubmit}>
                <div>
                    <Container>
                        <Row>
                            <Col xl="4" lg="4" md="5" xs="12">

                                <h6 className="newh6">{this.props.title} - {this.props.originalPrice} KM</h6>
                                <h6 className="newh6-2">UKUPNO: &nbsp;&nbsp;<span>{this.props.totalPrice + decoratorsPrice + foodPrice} KM</span></h6>

                            </Col>
                        </Row>
                        <Row noGutters className="optionButtons">
                            <AnimatedButton buttonText={"Odaberite dodatne opcije"} onClick={() => this.setState({ selected: 0 })} type={"button"}/>
                            <AnimatedButton buttonText={"Odaberite hranu"} onClick={() => this.setState({ selected: 1 })} type={"button"}/>
                        </Row>
                        <Row noGutters>
                            <Row className="selectOptions">
                                {this.state.selected == 0 && this.props.decorators.map((item, idx) => {
                                    return (
                                        <Col xl="6" lg="6" md="6" xs="12" key={idx}>
                                            <div className="optionFix">
                                                <Field component={renderCheckField} type="checkbox" name={'decoratorsObj.' + item.title} placeholder={item.title}></Field>
                                                <p><span>+{item.price} KM</span></p>
                                            </div>
                                        </Col>
                                    )

                                })}

                                {this.state.selected == 1 && this.props.foodDecorators.map((item, idx) => {

                                    return (
                                        <Col xl="6" lg="6" md="6" xs="12" key={idx}>
                                            <div className="optionFix">
                                                <Field component={renderCheckField} type="checkbox" name={'foodObj.' + item.title} placeholder={item.title}></Field>
                                                <p><span>+{item.price} KM</span></p>
                                            </div>
                                        </Col>
                                    )

                                })}

                            </Row>
                        </Row>

                    </Container>
                </div>

                <div className="reservationForm">
                    <div className="contactForm">
                        <Container>
                            <h6>Popunite podatke potrebne za rezervaciju termina</h6>
                            <Row>
                                <Col xl="6" lg="6" md="6" xs="12">
                                    <Field validate={[required]} component={renderTextField} type="text" name="name" placeholder="Ime i prezime"></Field>
                                </Col>
                                <Col xl="6" lg="6" md="6" xs="12">
                                    <Field validate={[required]} component={renderTextField} type="text" name="phone" placeholder="Telefon"></Field>
                                </Col>
                                <Col xl="6" lg="6" md="6" xs="12">
                                    <Field validate={[required, email]} component={renderTextField} type="email" name="email" placeholder="E-mail"></Field>
                                </Col>

                                <Col xl="3" lg="3" md="3" xs="12">
                                    <Field validate={[required]} component={renderTextField} type="text" name="zaodrasle" placeholder="Broj odraslih"></Field>
                                </Col>
                                <Col xl="3" lg="3" md="3" xs="12">
                                    <Field validate={[required]} component={renderTextField} type="text" name="zadjecu" placeholder="Broj djece"></Field>
                                </Col>

                                <Col xl="6" lg="6" md="6" xs="12">
                                    <FormGroup>
                                        <Field validate={[required]} component={renderDateField} name="reservation_date" placeholder="Datum" />
                                    </FormGroup>
                                </Col>
                                <Col xl="3" lg="3" md="3" xs="12">
                                    <FormGroup>
                                        <Field component={renderTimeField} name="timeFrom" placeholder="Od" />
                                    </FormGroup>
                                </Col>
                                <Col xl="3" lg="3" md="3" xs="12">
                                    <FormGroup>
                                        <Field component={renderTimeField} name="timeTo" placeholder="Do" />
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12" xs="12">
                                    <Field validate={[required]} component={renderTextareaField} type="textarea" name="comment" placeholder="Napomena"></Field>
                                </Col>
                                <div className="contactButton">
                                    <AnimatedButton buttonText={"Rezerviši"} />
                                </div>
                            </Row>

                        </Container>

                    </div>
                </div>


            </form >


        )

    }
}



// The order of the decoration does not matter.

// Decorate with redux-form
SelectingFormValuesForm = reduxForm({
    form: 'optionsForm' // a unique identifier for this form
  })(SelectingFormValuesForm)
  
  // Decorate with connect to read form values
  const selector = formValueSelector('optionsForm') // <-- same as form name
  SelectingFormValuesForm = connect(state => {
    // can select values individually
    const decoratorsObj = selector(state, 'decoratorsObj')
    const foodObj = selector(state, 'foodObj')

    return {
        decoratorsObj,
        foodObj
    }
  })(SelectingFormValuesForm)
  
export default SelectingFormValuesForm
/*
export default reduxForm({
    form: 'optionsForm',
})(optionsForm)

*/