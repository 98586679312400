import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';
import AnimtedButton from '../../components/animatedButton.js';
import showResults from '../../showResults';

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';

import { renderTextField, renderTextareaField, email } from '../../components/fields/fields.js'


const required = value => value ? undefined : "Required"

const form = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;

    const buttonText = "Pošalji poruku";
    return (
            <form onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <Field validate={[required]} component={renderTextField} type="text" name="name" placeholder="Ime i prezime"></Field>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <Field validate={[required]} component={renderTextField} type="text" name="phone" placeholder="Telefon"></Field>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <Field validate={[required, email]} component={renderTextField} type="email" name="EMail" placeholder="E-mail"></Field>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <Field validate={[required]} component={renderTextField} type="text" name="occasion" placeholder="Povod"></Field>
                        </Col>
                        <Col xl="12" lg="12" md="12" xs="12">
                            <Field validate={[required]} component={renderTextareaField} type="textarea" name="text" placeholder="Poruka"></Field>
                        </Col>
                        <div className="contactButton">
                            <AnimtedButton buttonText={buttonText} />
                        </div>
                    </Row>
                    {/*
            <Row>
                <Col xl="12">
                    <div class="alert alert-info mt-4" role="alert">Vaša poruka je uspješno poslata!</div>
                </Col>
            </Row>
            */}
                </Container>

            </form>
    )
}
export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
