import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Isvg from 'react-inlinesvg';
import AnimtedButton from '../../components/animatedButton';
import showResults from '../../showResults';

import {
    Container, Row, Col, Button, FormGroup,
    Input, NavItem
} from 'reactstrap';

import { renderTextField, renderTextareaField, renderDateField, renderTimeField, email, renderCheckField } from '../../components/fields/fields.js'


const required = value => value ? undefined : "Required"

const reservationForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;

    const buttonText = "Rezerviši";
    return (
        <div className="contactForm">
            <form onSubmit={handleSubmit}>
                <Container>
                    <h6>Popunite podatke potrebne za rezervaciju termina</h6>
                    <Row>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <Field validate={[required]} component={renderTextField} type="text" name="name" placeholder="Ime i prezime"></Field>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <Field validate={[required]} component={renderTextField} type="text" name="phone" placeholder="Telefon"></Field>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <Field validate={[required, email]} component={renderTextField} type="email" name="email" placeholder="E-mail"></Field>
                        </Col>

                        <Col xl="6" lg="6" md="6" xs="12">
                            <Field validate={[required]} component={renderTextField} type="text" name="corpses" placeholder="Broj osoba"></Field>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="12">
                            <FormGroup>
                                <Field validate={[required]} component={renderDateField} name="reservation_date" placeholder="Datum" />
                            </FormGroup>
                        </Col>
                        <Col xl="3" lg="3" md="3" xs="12">
                            <FormGroup>
                                <Field component={renderTimeField} name="timeFrom" placeholder="Od" />
                            </FormGroup>
                        </Col>
                        <Col xl="3" lg="3" md="3" xs="12">
                            <FormGroup>
                                <Field component={renderTimeField} name="timeTo" placeholder="Do" />
                            </FormGroup>
                        </Col>
                        <Col xl="12" lg="12" md="12" xs="12">
                            <Field validate={[required]} component={renderTextareaField} type="textarea" name="comment" placeholder="Napomena"></Field>
                        </Col>
                        <div className="contactButton">
                            <AnimtedButton buttonText={buttonText} />
                        </div>
                    </Row>
                    {/*
            <Row>
                <Col xl="12">
                    <div class="alert alert-info mt-4" role="alert">Vaša poruka je uspješno poslata!</div>
                </Col>
            </Row>
            */}
                </Container>

            </form>
        </div>
    )
}
export default reduxForm({
    form: 'reservationForm'
})(reservationForm)
