import React, { Component } from 'react'
import DefaultLayout from '../containers/defaultLayout.js';
import Carousel from '../containers/laser/carousel.js';
import Welcome from '../containers/laser/welcome.js';
import HomeOffer from '../containers/laser/homeOffer.js';
import ActivityOffer from '../containers/laser/activityOffer.js';
import Gallery from '../containers/laserGallery.js';
import { Link } from 'react-router-dom';
import AnimatedButton from '../components/animatedButton.js';
import HappeningComponent from '../containers/kids/happeningComponent.js';
import ContactForm from '../containers/contactForm.js';

import image1 from '../images/happening/image1.jpg';
import image2 from '../images/happening/image2.jpg';
import axios from 'axios';

import {
    Container, Row, Col
} from 'reactstrap';

import blueGun from '../images/laser/blueGun.png';
import happeningLaser from '../images/laser/happeningLaser.png';
import moment from 'moment';


export class laser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.props.setType('laser');

        const API_KEY = "IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo";

        axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + API_KEY
        }

        axios.get(`https://euroexpress.tips:4003/api/v1/pub/article_all`)
            .then(res => {
                let data = res.data;
                data = data.slice(-2)

                this.setState({ data });
            })

    }
    render() {
        const buttonText = "Pogledaj kompletnu galeriju";
        const buttonText2 = "Pogledaj sva dešavanja";
        return (
            <div>
                <Carousel />
                <Welcome />
                <HomeOffer />
                <ActivityOffer />

                <div className="homeGallery vr">
                    <Container>
                        <Row>
                            <div className="title">
                                <h1>Galerija</h1>
                                <p>{/*----*/}</p>
                            </div>
                        </Row>
                        <Gallery />
                        <div className="homeButtonWrapper">
                            <Link to="/galerija"><AnimatedButton buttonText={buttonText} /></Link>
                        </div>
                    </Container>
                </div>


                <div className="happening">

                    <div className="vrAnim">
                        <img src={blueGun} className="gun" />
                        <img src={happeningLaser} className="laser" />
                    </div>
                    <Container>
                        <h1>Dešavanja</h1>
                        <p>{/*----*/}</p>
                        <Row>
                            {this.state.data.map((items, idx) => {
                                const ENDPOINT = "https://euroexpress.tips:4002";
                                const IMAGES = ENDPOINT + "/images/image_get/";
                                return (
                                    <Col xl="6" lg="6" md="6" xs="12" key={items.id}>
                                        <div className="boxHappening">
                                            <div className="contentHappening">
                                                <Link to={`/desavanja/${this.props.type}/${items.id}`}>
                                                    <h5>{items.title}</h5></Link>
                                                <h6>{moment.unix(items.timestamp).format('DD.MM.YYYY HH:mm')}</h6>
                                                <p>{items.text}</p>
                                            </div>
                                            <div className="image">
                                                <Link to={`/desavanja/${this.props.type}/${items.id}`}>
                                                    <img src={IMAGES + items.banner.url} className="img-fluid" />
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>

                                )
                            })}
                        </Row>

                        <Link to={`/desavanja/${this.props.type}`}><AnimatedButton buttonText={buttonText2} /></Link>
                    </Container>
                </div>
                <ContactForm />
            </div>
        )
    }
}

export default DefaultLayout(laser)
